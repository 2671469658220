import { string, number } from 'yup';

export default {
    title: string().required('حقل عنوان الإعلان مطلوب'),
    postal_code: number().typeError('يجب إدخال رقم بريدى صحيح'),
    rooms: number().required('يجب تحديد عدد الغرف').typeError('يجب تحديد عدد الغرف'),
    beds: number().required('يجب تحديد عدد الأسرة').typeError('يجب تحديد عدد الأسرة'),
    'dual_beds': number().required('يجب تحديد عدد الأسرة الزوجية').typeError('يجب تحديد عدد الأسرة الزوجية'),
    'single_beds': number().required('يجب تحديد عدد الأسرة الفردية').typeError('يجب تحديد عدد الأسرة الفردية'),
    bathrooms: number().required('يجب تحديد عدد الحمامات').typeError('يجب تحديد عدد الحمامات'),
    guests: number().required('يجب تحديد عدد الضيوف المتاحين').typeError('يجب تحديد عدد الضيوف المتاحين'),
    price_1: number().required('يجب تحديد السعر').typeError('يجب تحديد السعر'),
    note_1: string().required('يجب ذكر بداية ونهاية الفترة'),
    price_2: number().required('يجب تحديد السعر').typeError('يجب تحديد السعر'),
    note_2: string().required('يجب ذكر بداية ونهاية الفترة'),
    price_3: number().required('يجب تحديد السعر').typeError('يجب تحديد السعر'),
    note_3: string().required('يجب ذكر بداية ونهاية الفترة'),
    // birthday_party_price: number().typeError('يجب إدخال رقم صحيح'),
    // wedding_party_price: number().typeError('يجب إدخال رقم صحيح'),
    // other_party_price: number().typeError('يجب إدخال رقم صحيح'),
    address: string().required('يجب إدخال عنوان العقار'),
}